import React, {  useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
// <<<<<<< HEAD
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// =======
// import { BrowserRouter as  Switch } from "react-router-dom";
// >>>>>>> f82cfee9693d586e327a5e0e777d3fac189be1be
import Home from './components/home';
import About from './components/about';
import Services from './components/services';
import Gallery from './components/gallery';
import Contact from './components/contact';
import { ServicesDetail } from "./components/section-components/ServicesDetail";
import { GlobalDataContext } from './context/context';
import axios from 'axios'


function Root() {


  const id = "62f2d0e5d20ef0321d54986d"
  const [rpdata, setrpdata] = useState({});


  //useEffect para peticion al servidor
  useEffect(() => {
    // console.log(“haciendo fetch”)
    async function fetchData() {

      try {
        const response = await axios({
          baseURL: "https://paginasserver.herokuapp.com/api",
          url: `/paginas/${id}`,
          method: "get"
        });
        console.log(response.data)
        //se convierte la data en el objeto
        setrpdata(JSON.parse(JSON.stringify(response.data)));
        // console.log(rpdata)

      } catch (error) {
        console.log(error);

      }
    };
    fetchData();
  }, [])


  return (
    <>
      <GlobalDataContext.Provider value={{ rpdata }}>
        <Router>

          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />}></Route>
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/services/:id" element={<ServicesDetail />} />
          </Routes>

        </Router>
      </GlobalDataContext.Provider>
    </>
  )
}

export default Root;

ReactDOM.render(<Root />, document.getElementById('mingrand'));
