import React from "react";
// import { GlobalDataContext } from "../../context/context";

//

const LiveChat = (props) => {
  // const { rpdata } = useContext(GlobalDataContext);

  return (
    <div>
<div class="elfsight-app-394724ec-54c4-49b3-9fce-2001f3455606"></div>
      <div className="visor_Counter">
<div class="elfsight-app-b8a56f1e-13f0-420d-a542-091d4e218b2d"></div>
      </div>
    </div>
  );
};

export default LiveChat;
